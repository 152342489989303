export const userConstants = {
  LOGOUT: 'USERS_LOGOUT',
  LOGIN_SCREEN: 'LOGIN_SCREEN',
  SIGNUP_SCREEN: 'SIGNUP SCREEN',
  DUPLICATE_USER: 'DUPLICATE_USER',
  SIGNUP_SUCCESS: 'SIGNUP SUCESS',
  LOAD_SCREEN: 'LOAD_SCREEN',
  EMAIL_RULES: /^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i,
  DEFAULT_LOGIN: '/login',
};
