export const networkStatus = {
  404: 'Method not found',
  200: 'success',
  401: 'User is expired, please login again for to access required page.',
  1300: 'All user activities details not found',
  1301: 'Exception occured',
  1302: 'Internal server error',
  1201: 'UserNameisRequired',
  1202: 'InvalidUserName',
  1203: 'User details not found',
  1204: 'Something went wrong, please try after sometime!',
  1205: 'Internal server error, please try after sometime!',

  901: 'Something went wrong, please try after sometime!',
  902: 'Internal server error, please try after sometime!',

  700: 'UserNameisRequired',
  701: 'InvalidUserName',
  702: 'PasswordisRequired',
  704: 'Something went wrong, please try after sometime !',
  705: 'Username or Password is incorrect',
};
