import {
  MORE_APPS_DATA,
  GET_MOREAPPS_DATA_FAILURE,
} from '../Actions/ActionTypes';

const initialState = {
  moreAppsData: [],
  moreAppsDataFailure: null,
};

export default function MoreAppsReducer(state = initialState, action) {
  switch (action.type) {
    case MORE_APPS_DATA:
      return {
        ...state,
        moreAppsData: action.moreAppsData,
        moreAppsDataFailure: null,
      };
    case GET_MOREAPPS_DATA_FAILURE:
      return {
        ...state,
        moreAppsDataFailure: action.error,
        moreAppsData: [],
      };
    default:
      return state;
  }
}
