import {
  FETCH_APPLICATION_DATA_SUCCESS,
  FETCH_APPLICATION_DATA_FAILURE,
  SHOW_LOADER,
} from '../Actions/ActionTypes';

const initialState = {
  appData: null,
  appDataFailure: null,
};
/**
 * Reducer for Hub Page
 * @param  state
 * @param  action
 */
export default function HubReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_APPLICATION_DATA_SUCCESS:
      return {
        ...state,
        appData: action.payload,
        appDataFailure: null,
      };
    case FETCH_APPLICATION_DATA_FAILURE:
      return {
        appDataFailure: action.error,
        appData: null,
      };
    case SHOW_LOADER:
      return {
        ...state,
        isLoader: action.isLoader,
      };
    default:
      return state;
  }
}
