/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Route, Switch, Router, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Footer, Notification, IamNav } from '@weirgroup/weir-dls';
import { useMsal } from '@azure/msal-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { msalConstants } from '../../services/msal.constants';
import { history } from '../../services/_helpers';
import styles from './main.module.css';
import { applicationRouteConstants } from '../../shared/_constants';
import '../../resources/stylesheets/main.css';
import SiteInformation from '../../modules/SiteInformation';
import PrivateRoute from './protectedRoute';
import Loader from '../../components/Loader/Loader';
import { localStorageOperation } from '../../shared/Helpers/localStorageData';
import { localStorageKeys } from '../../shared/_constants/localStorageKeys';
import { notificationActions } from '../../shared/_actions';

const MyAccessPage = lazy(() => import('../../modules/MyAccess'));
const MyActivityPage = lazy(
  () => import('../../modules/MyActivity/containers/MyActivityPage.container')
);

const LoginPage = lazy(() => import('../../modules/Login'));
const HubPage = lazy(
  () => import('../../modules/Hub/containers/HubPage.container')
);
const SignUpPage = lazy(() => import('../../modules/SignUp'));
const AppSignUp = lazy(() => import('../../modules/AppSignUp'));
const ErrorPage = lazy(() => import('../../modules/ErrorPage/ErrorPage'));
const SelectionPage = lazy(() => import('../../modules/SelectionPage'));

const ApplicationRoutes = () => {
  const location = useLocation();
  const appID = window.location.search.indexOf('app_id');
  const [autoPageHeight, setAutoPageHeight] = useState(false);

  useEffect(() => {
    if (
      location.pathname === '/login' ||
      location.pathname === '/' ||
      location.pathname === '/selection-page'
    ) {
      if (!autoPageHeight) setAutoPageHeight(true);
    } else if (autoPageHeight) {
      setAutoPageHeight(false);
    }
  }, [location, autoPageHeight]);

  return (
    <div
      className={`${styles.contentMain} mainHeight ${
        autoPageHeight ? styles.heightAuto : ''
      }`}
    >
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/" exact component={LoginPage} />
          <Route
            path={`${applicationRouteConstants.LOGIN}`}
            component={LoginPage}
          />
          <Route
            path={`${applicationRouteConstants.SIGNUP}`}
            component={appID >= 0 ? AppSignUp : SignUpPage}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.HUB}`}
            component={HubPage}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.MY_ACCESS}`}
            component={MyAccessPage}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.MY_ACTIVITY}`}
            component={MyActivityPage}
          />
          <Route
            path={`${applicationRouteConstants.SiteInformation}`}
            component={SiteInformation}
          />
          <PrivateRoute
            path={`${applicationRouteConstants.SELECTION_PAGE}`}
            component={SelectionPage}
          />
          <Route component={ErrorPage} />
        </Switch>
      </Suspense>
    </div>
  );
};

const Main = ({ notification, hideNotification, showNotification, t }) => {
  const { instance } = useMsal();
  const detectPopupBlocker = () => {
    const popup = window.open('', '', 'width=100,height=100');
    try {
      popup.close();
    } catch (e) {
      showNotification(
        'alert',
        'Pop-ups blocked',
        'Please update the browser settings to allow pop-ups'
      );
    }
  };

  useEffect(() => {
    window.onpopstate = () => {
      window.location.assign(window.location.pathname);
    };
    detectPopupBlocker();

    let timeoutId;
    if (notification.showNotification) {
      timeoutId = setTimeout(() => {
        if (notification.showNotification) {
          hideNotification();
          notification.onClose?.();
        }
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [notification, hideNotification]);

  const isAuthenticatedUser = () => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/login' ||
      window.location.pathname === '/register'
    ) {
      return false;
    }
    return true;
  };

  const showApplicationNavigation = () => {
    if (
      window.location.pathname === applicationRouteConstants.LOGIN ||
      window.location.pathname === applicationRouteConstants.SIGNUP ||
      window.location.pathname === applicationRouteConstants.SELECTION_PAGE
    ) {
      return false;
    }
    const userInfo = instance.getAccountByUsername(
      localStorageOperation.getDataFromLocalStorage(localStorageKeys.userName)
    );

    // Simplified return statement
    return !(userInfo === null || userInfo === undefined);
  };

  showApplicationNavigation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router history={history}>
      <div>
        <div
          className={
            isAuthenticatedUser() ? styles.iamNavAuth : styles.iamNavUnAuth
          }
        >
          <IamNav
            appId={msalConstants.portalApplicationConfig.clientID}
            history={history}
            inAppNav
            contactTitle={t('contactForm.contactTitle')}
            contactAppname={t('contactForm.contactAppname')}
            subjectRequired={t('contactForm.subjectRequired')}
            subjectLabel={t('contactForm.subjectLabel')}
            subjectPlaceholder={t('contactForm.subjectPlaceholder')}
            messageRequired={t('contactForm.messageRequired')}
            messageLabel={t('contactForm.messageLabel')}
            messagePlaceholder={t('contactForm.messagePlaceholder')}
            buttonLabelClose={t('contactForm.buttonLabelClose')}
            buttonLabelSubmit={t('contactForm.buttonLabelSubmit')}
            contactDescription={t('contactForm.contactDescription')}
            notificationTitleSuccess={t('contactForm.notificationTitleSuccess')}
            notificationTitleError={t('contactForm.notificationTitleError')}
            notificationTextContent={t('contactForm.notificationTextContent')}
            redirectUri={`${window.location.origin}/blank.html`}
          />
        </div>
        {notification.showNotification && (
          <div className={styles.notification}>
            <Notification
              variant={notification.notificationType}
              title={notification.notificationTitle}
              onClose={() => {
                hideNotification();
                notification.onClose?.();
              }}
              textContent={notification.notificationMessage}
              callToActionList={notification.callToActionList}
            />
          </div>
        )}
        <ApplicationRoutes />

        <Footer
          logoName="WeirLogo"
          InformationText={t('footerTitle')}
          CopyrightText={t('footerCopyRightTitle')}
          NavigationLinks={[
            {
              url: '/site-information/terms-of-conditions',
              name: 'Terms & Conditions',
            },
            {
              url: '/site-information/privacy-statement',
              name: 'Privacy Statement',
            },
            {
              url: '/site-information/cookies-policy',
              name: 'Cookies Policy',
            },
          ]}
        />
      </div>
    </Router>
  );
};

Main.propTypes = {
  t: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  notificationType: PropTypes.func.isRequired,
  notificationMessage: PropTypes.func.isRequired,
  callToActionList: PropTypes.func.isRequired,
  notificationTitle: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isModal: state.HubReducer.isModal,
    notification: state.notification,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    hideNotification: () => {
      dispatch(notificationActions.hideNotification());
    },
    showNotification: (type, title, message) => {
      dispatch(
        notificationActions.showNotification({
          notificationType: type,
          notificationTitle: title,
          notificationMessage: message,
        })
      );
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Main));
