import { notificationConstants } from '../_constants/notification.constants';

const hideNotification = () => ({
  type: notificationConstants.HIDE_NOTIFICATION,
});

const showNotification = (payload) => ({
  type: notificationConstants.SHOW_NOTIFICATION,
  payload,
});

export const notificationActions = {
  hideNotification,
  showNotification,
};
