import { userConstants } from './constants';

export default function signUp(
  state = {
    signUpStatus: false,
    signUpSuccess: false,
    duplicateUser: null,
  },
  action
) {
  switch (action.type) {
    case userConstants.SIGNUP_SCREEN:
      return {
        ...state,
        signUpStatus: action.payload,
      };
    case userConstants.SIGNUP_SUCCESS:
      return {
        ...state,
        signUpSuccess: action.payload,
      };
    case userConstants.DUPLICATE_USER:
      return {
        ...state,
        duplicateUser: action.duplicateUser,
      };
    default:
      return state;
  }
}
