import { APPLICATION_MANIFEST } from '../Actions/ActionTypes';
import { userConstants } from '../constants';

const initialState = {
  appManifest: {},
  userReactivationSuccess: false,
};

export default function LoginReducer(state = initialState, action) {
  switch (action.type) {
    case APPLICATION_MANIFEST:
      return {
        ...state,
        appManifest: action.payload,
      };
    case userConstants.REACTIVATE_USER_SUCCESS:
      return {
        ...state,
        userReactivationSuccess: action.payload,
      };
    default:
      return state;
  }
}
