import { loaderConstants } from '../_constants';

function show() {
  return { type: loaderConstants.START };
}

function hide() {
  return { type: loaderConstants.STOP };
}

export const loaderActions = {
  show,
  hide,
};
