import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../resources/stylesheets/shared.module.css';

/* This generates the CookiesPolicy templates */
export default function CookiesPolicy() {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.h1headerAlign}>
        <span className={styles.h1header} data-testid="CookiesPolicy_1">
          {t('cookiesPolicys.CookiesPolicy_1')}
        </span>
      </div>
      <div>
        <div className={styles.h3header} style={{ marginTop: '15px' }}>
          <span id="CookiesPolicy_2">
            {t('cookiesPolicys.CookiesPolicy_2')}
          </span>
        </div>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="CookiesPolicy_3">
            {t('cookiesPolicys.CookiesPolicy_3')}
          </span>
        </p>
        <div className={styles.h3header}>
          <span id="CookiesPolicy_4">
            {t('cookiesPolicys.CookiesPolicy_4')}
          </span>
        </div>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="CookiesPolicy_5">
            {t('cookiesPolicys.CookiesPolicy_5')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="CookiesPolicy_6">
            {t('cookiesPolicys.CookiesPolicy_6')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="CookiesPolicy_7">
            {t('cookiesPolicys.CookiesPolicy_7')}
          </span>
        </p>
        <div className={styles.h3header}>
          <span id="CookiesPolicy_8">
            {t('cookiesPolicys.CookiesPolicy_8')}
          </span>
        </div>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="CookiesPolicy_9">
            {t('cookiesPolicys.CookiesPolicy_9')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="CookiesPolicy_10">
            {t('cookiesPolicys.CookiesPolicy_10')}
          </span>
        </p>
        <div className={styles.h3header}>
          <span id="CookiesPolicy_11">
            {t('cookiesPolicys.CookiesPolicy_11')}
          </span>
        </div>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="CookiesPolicy_12">
            {t('cookiesPolicys.CookiesPolicy_12')}
          </span>
          <a
            className={`${styles.anchorTagFont} ${styles.linkSpacing}`}
            href="http://www.allaboutcookies.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('cookiesPolicys.CookiesORG')}
          </a>
          <a
            className={`${styles.anchorTagFont} ${styles.linkSpacing}`}
            href="http://www.youronlinechoices.eu"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('cookiesPolicys.CookiesOnlineChoice')}
          </a>
        </p>
        <div className={styles.h3header}>
          <span id="CookiesPolicy_13">
            {t('cookiesPolicys.CookiesPolicy_13')}
          </span>
        </div>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="CookiesPolicy_14">
            {t('cookiesPolicys.CookiesPolicy_14')}
          </span>
        </p>
        <table className={styles.cookiesTable}>
          <thead className={styles.tablehead}>
            <tr className={styles.tablehead}>
              <td>
                <span className={styles.textType} id="CookiesPolicy_15">
                  {t('cookiesPolicys.CookiesPolicy_15')}
                </span>
                &nbsp;
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_16">
                  {t('cookiesPolicys.CookiesPolicy_16')}
                </span>
                &nbsp;
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_17">
                  {t('cookiesPolicys.CookiesPolicy_17')}
                </span>
                &nbsp;
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className={styles.textType} id="CookiesPolicy_18">
                  {t('cookiesPolicys.CookiesPolicy_18')}
                </span>
              </td>
              <td>
                <span>
                  <span className={styles.textType} id="CookiesPolicy_19">
                    {t('cookiesPolicys.CookiesPolicy_9')}
                  </span>
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_20">
                  {t('cookiesPolicys.CookiesPolicy_20')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className={styles.textType} id="CookiesPolicy_21">
                  {t('cookiesPolicys.CookiesPolicy_21')}
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_22">
                  {t('cookiesPolicys.CookiesPolicy_22')}
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_23">
                  {t('cookiesPolicys.CookiesPolicy_23')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className={styles.textType} id="CookiesPolicy_24">
                  {t('cookiesPolicys.CookiesPolicy_24')}
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_25">
                  {t('cookiesPolicys.CookiesPolicy_25')}
                </span>
                &nbsp;
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_26">
                  {t('cookiesPolicys.CookiesPolicy_26')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className={styles.textType} id="CookiesPolicy_27">
                  {t('cookiesPolicys.CookiesPolicy_27')}
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_28">
                  {t('cookiesPolicys.CookiesPolicy_28')}
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_29">
                  {t('cookiesPolicys.CookiesPolicy_29')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className={styles.textType} id="CookiesPolicy_30">
                  {t('cookiesPolicys.CookiesPolicy_30')}
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_31">
                  {t('cookiesPolicys.CookiesPolicy_31')}
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_32">
                  {t('cookiesPolicys.CookiesPolicy_32')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  <span className={styles.textType} id="CookiesPolicy_33">
                    {t('cookiesPolicys.CookiesPolicy_33')}
                  </span>
                </span>
              </td>
              <td>
                <span>
                  <span className={styles.textType} id="CookiesPolicy_34">
                    {t('cookiesPolicys.CookiesPolicy_34')}
                  </span>
                  &nbsp;
                </span>
                <a
                  className={styles.anchorTagFont}
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span id="CookiesPolicy_34_1">
                    {t('cookiesPolicys.CookiesPolicy_34_1')}
                  </span>
                </a>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_35">
                  {t('cookiesPolicys.CookiesPolicy_35')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className={styles.textType} id="CookiesPolicy_38">
                  {t('cookiesPolicys.CookiesPolicy_38')}
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_39">
                  {t('cookiesPolicys.CookiesPolicy_39')}
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_40">
                  {t('cookiesPolicys.CookiesPolicy_40')}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className={styles.textType} id="CookiesPolicy_41">
                  {t('cookiesPolicys.CookiesPolicy_41')}
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_42">
                  {t('cookiesPolicys.CookiesPolicy_42')}
                </span>
              </td>
              <td>
                <span className={styles.textType} id="CookiesPolicy_43">
                  {t('cookiesPolicys.CookiesPolicy_43')}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className={styles.h3header}>
          <span id="CookiesPolicy_36">
            {t('cookiesPolicys.CookiesPolicy_36')}
          </span>
        </div>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="CookiesPolicy_37">
            {t('cookiesPolicys.CookiesPolicy_37')}
          </span>{' '}
          <a className={styles.anchorTagFont} href="mailto:web@mail.weir">
            {t('cookiesPolicys.CookiesWebmail')}
          </a>
        </p>
      </div>
    </div>
  );
}
