export const localStorageKeys = {
  authToken: 'authToken',
  userDetails: 'userDetails',
  appName: 'appName',
  cookiesDetails: 'cookiesDetails',
  idToken: 'msal.idtoken',
  cookiesAcceptedState: 'cookiesAcceptedState',
  userName: 'UserName',
  appId: 'appId',
};
