import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../resources/stylesheets/shared.module.css';

/* This generates the Privacy Policy templates */
export default function PrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.h1headerAlign}>
        <span className={styles.h1header} data-testid="PRIVACYPOLICY_1">
          {t('privacyPolicy.PRIVACYPOLICY_1')}
        </span>
      </div>
      <div style={{ marginTop: '10px' }}>
        <span className={styles.h3header} id="PRIVACYPOLICY_2">
          {t('privacyPolicy.PRIVACYPOLICY_2')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="PRIVACYPOLICY_3">
          {t('privacyPolicy.PRIVACYPOLICY_3')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="PRIVACYPOLICY_4">
          {t('privacyPolicy.PRIVACYPOLICY_4')}
        </span>
        <a
          className={styles.anchorTagFont}
          href="https://your.weir/kiosk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span id="PRIVACYPOLICY_4_1">
            {t('privacyPolicy.PRIVACYPOLICY_4_1')}
          </span>
        </a>
        <span className={styles.textType} id="PRIVACYPOLICY_4_2">
          {t('privacyPolicy.PRIVACYPOLICY_4_2')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="PRIVACYPOLICY_5">
          {t('privacyPolicy.PRIVACYPOLICY_5')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="PRIVACYPOLICY_6">
          {t('privacyPolicy.PRIVACYPOLICY_6')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <strong>
          <span className={styles.textType} id="PRIVACYPOLICY_7">
            {t('privacyPolicy.PRIVACYPOLICY_7')}
          </span>
        </strong>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="PRIVACYPOLICY_8">
          {t('privacyPolicy.PRIVACYPOLICY_8')}
        </span>
      </p>

      <div className={styles.h3header}>
        <span id="PRIVACYPOLICY_9">{t('privacyPolicy.PRIVACYPOLICY_9')}</span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="PRIVACYPOLICY_10">
          {t('privacyPolicy.PRIVACYPOLICY_10')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <strong>
          <span className={styles.textType} id="PRIVACYPOLICY_11">
            {t('privacyPolicy.PRIVACYPOLICY_11')}
          </span>
        </strong>{' '}
        <span className={styles.textType} id="PRIVACYPOLICY_12">
          {t('privacyPolicy.PRIVACYPOLICY_12')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <strong>
          <span className={styles.textType} id="PRIVACYPOLICY_13">
            {t('privacyPolicy.PRIVACYPOLICY_13')}
          </span>
        </strong>{' '}
        <span className={styles.textType} id="PRIVACYPOLICY_14">
          {t('privacyPolicy.PRIVACYPOLICY_14')}
        </span>
      </p>
      <span className={styles.textType} id="PRIVACYPOLICY_15">
        {t('privacyPolicy.PRIVACYPOLICY_15')}
      </span>
      <span className={styles.textType} id="PRIVACYPOLICY_16">
        {t('privacyPolicy.PRIVACYPOLICY_16')}
      </span>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="PRIVACYPOLICY_17">
          {t('privacyPolicy.PRIVACYPOLICY_17')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <strong>
          <span className={styles.textType} id="PRIVACYPOLICY_18">
            {t('privacyPolicy.PRIVACYPOLICY_18')}
          </span>
        </strong>{' '}
        <span className={styles.textType} id="PRIVACYPOLICY_19">
          {t('privacyPolicy.PRIVACYPOLICY_19')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <strong>
          <span className={styles.textType} id="PRIVACYPOLICY_20">
            {t('privacyPolicy.PRIVACYPOLICY_20')}
          </span>
        </strong>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="PRIVACYPOLICY_21">
          {t('privacyPolicy.PRIVACYPOLICY_21')}
        </span>
      </p>

      <div className={styles.h3header}>
        <span id="PRIVACYPOLICY_22">{t('privacyPolicy.PRIVACYPOLICY_22')}</span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="PRIVACYPOLICY_23">
          {t('privacyPolicy.PRIVACYPOLICY_23')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <strong>
          <span className={styles.textType} id="PRIVACYPOLICY_24">
            {t('privacyPolicy.PRIVACYPOLICY_24')}
          </span>
        </strong>
        {':'}
      </p>
      <p>
        ›{' '}
        <span
          style={{ marginLeft: '15px' }}
          className={styles.textType}
          id="PRIVACYPOLICY_25"
        >
          {t('privacyPolicy.PRIVACYPOLICY_25')}
        </span>
      </p>
      <p>
        ›{' '}
        <span
          style={{ marginLeft: '15px' }}
          className={styles.textType}
          id="PRIVACYPOLICY_26"
        >
          {t('privacyPolicy.PRIVACYPOLICY_26')}
        </span>
      </p>
      <p>
        ›{' '}
        <span
          style={{ marginLeft: '15px' }}
          className={styles.textType}
          id="PRIVACYPOLICY_27"
        >
          {t('privacyPolicy.PRIVACYPOLICY_27')}
        </span>
      </p>
      <p>
        ›{' '}
        <span
          style={{ marginLeft: '15px' }}
          className={styles.textType}
          id="PRIVACYPOLICY_28"
        >
          {t('privacyPolicy.PRIVACYPOLICY_28')}
        </span>
      </p>
      <p>
        ›{' '}
        <span
          style={{ marginLeft: '15px' }}
          className={styles.textType}
          id="PRIVACYPOLICY_29"
        >
          {t('privacyPolicy.PRIVACYPOLICY_29')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <strong>
          <span className={styles.textType} id="PRIVACYPOLICY_30">
            {t('privacyPolicy.PRIVACYPOLICY_30')}
          </span>
        </strong>
        {':'}
      </p>
      <p>
        ›{' '}
        <span
          style={{ marginLeft: '15px' }}
          className={styles.textType}
          id="PRIVACYPOLICY_31"
        >
          {t('privacyPolicy.PRIVACYPOLICY_31')}
        </span>
      </p>
      <p>
        ›{' '}
        <span
          style={{ marginLeft: '15px' }}
          className={styles.textType}
          id="PRIVACYPOLICY_32"
        >
          {t('privacyPolicy.PRIVACYPOLICY_32')}
        </span>
      </p>
      <p>
        ›{' '}
        <span
          style={{ marginLeft: '15px' }}
          className={styles.textType}
          id="PRIVACYPOLICY_33"
        >
          {t('privacyPolicy.PRIVACYPOLICY_33')}
        </span>
      </p>
      <p>
        ›{' '}
        <span
          style={{ marginLeft: '15px' }}
          className={styles.textType}
          id="PRIVACYPOLICY_34"
        >
          {t('privacyPolicy.PRIVACYPOLICY_34')}
        </span>
        &nbsp;
      </p>
      <p>
        ›{' '}
        <span
          style={{ marginLeft: '15px' }}
          className={styles.textType}
          id="PRIVACYPOLICY_35"
        >
          {t('privacyPolicy.PRIVACYPOLICY_35')}
        </span>
      </p>
      <p>
        ›{' '}
        <span
          style={{ marginLeft: '15px' }}
          className={styles.textType}
          id="PRIVACYPOLICY_36"
        >
          {t('privacyPolicy.PRIVACYPOLICY_36')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="PRIVACYPOLICY_37">
          {t('privacyPolicy.PRIVACYPOLICY_37')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="PRIVACYPOLICY_38">
          {t('privacyPolicy.PRIVACYPOLICY_38')}
        </span>
      </p>
      <div>
        <div className={styles.h3header}>
          <span id="PRIVACYPOLICY_39">
            {t('privacyPolicy.PRIVACYPOLICY_39')}
          </span>
        </div>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="PRIVACYPOLICY_40">
            {t('privacyPolicy.PRIVACYPOLICY_40')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <strong>
            <span className={styles.textType} id="PRIVACYPOLICY_41">
              {t('privacyPolicy.PRIVACYPOLICY_41')}
            </span>
          </strong>
        </p>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="PRIVACYPOLICY_42">
            {t('privacyPolicy.PRIVACYPOLICY_42')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="PRIVACYPOLICY_43">
            {t('privacyPolicy.PRIVACYPOLICY_43')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="PRIVACYPOLICY_44">
            {t('privacyPolicy.PRIVACYPOLICY_44')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <strong>
            <span className={styles.textType} id="PRIVACYPOLICY_45">
              {t('privacyPolicy.PRIVACYPOLICY_45')}
            </span>
          </strong>
        </p>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="PRIVACYPOLICY_46">
            {t('privacyPolicy.PRIVACYPOLICY_46')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="PRIVACYPOLICY_47">
            {t('privacyPolicy.PRIVACYPOLICY_47')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <strong>
            <span className={styles.textType} id="PRIVACYPOLICY_48">
              {t('privacyPolicy.PRIVACYPOLICY_48')}
            </span>
          </strong>
        </p>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="PRIVACYPOLICY_49">
            {t('privacyPolicy.PRIVACYPOLICY_49')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="PRIVACYPOLICY_50">
            {t('privacyPolicy.PRIVACYPOLICY_50')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <strong>
            <span className={styles.textType} id="PRIVACYPOLICY_51">
              {t('privacyPolicy.PRIVACYPOLICY_51')}
            </span>
          </strong>
        </p>

        <p className={styles.paraAlign}>
          <span className={styles.textType} id="PRIVACYPOLICY_52">
            {t('privacyPolicy.PRIVACYPOLICY_52')}
          </span>
        </p>
        <p className={styles.paraAlign}>
          <span className={styles.textType} id="PRIVACYPOLICY_53">
            {t('privacyPolicy.PRIVACYPOLICY_53')}
          </span>
        </p>
        <div>
          <div className={styles.h4header}>
            <strong>
              <span id="PRIVACYPOLICY_54">
                {t('privacyPolicy.PRIVACYPOLICY_54')}
              </span>
            </strong>
          </div>
          <div className={styles.paraAlign}>
            <span className={styles.textType} id="PRIVACYPOLICY_55">
              {t('privacyPolicy.PRIVACYPOLICY_55')}
            </span>
            <div>
              <span className={styles.textType} id="PRIVACYPOLICY_56">
                {t('privacyPolicy.PRIVACYPOLICY_56')}
              </span>
              <div className={styles.h3header} style={{ marginTop: '15px' }}>
                <span id="PRIVACYPOLICY_57">
                  {t('privacyPolicy.PRIVACYPOLICY_57')}
                </span>
              </div>
              <div>
                <p className={styles.paraAlign}>
                  <span className={styles.textType} id="PRIVACYPOLICY_58">
                    {t('privacyPolicy.PRIVACYPOLICY_58')}
                  </span>
                </p>
                <p className={styles.paraAlign}>
                  <span className={styles.textType} id="PRIVACYPOLICY_59">
                    {t('privacyPolicy.PRIVACYPOLICY_59')}
                  </span>
                </p>
                <p>
                  ›{' '}
                  <span
                    style={{ marginLeft: '15px' }}
                    className={styles.textType}
                    id="PRIVACYPOLICY_60"
                  >
                    {t('privacyPolicy.PRIVACYPOLICY_60')}
                  </span>
                </p>
                <p>
                  ›{' '}
                  <span
                    style={{ marginLeft: '15px' }}
                    className={styles.textType}
                    id="PRIVACYPOLICY_61"
                  >
                    {t('privacyPolicy.PRIVACYPOLICY_61')}
                  </span>
                </p>
                <p>
                  ›{' '}
                  <span
                    style={{ marginLeft: '15px' }}
                    className={styles.textType}
                    id="PRIVACYPOLICY_63"
                  >
                    {t('privacyPolicy.PRIVACYPOLICY_63')}
                  </span>
                </p>
                <p className={styles.paraAlign}>
                  <span className={styles.textType} id="PRIVACYPOLICY_64">
                    {t('privacyPolicy.PRIVACYPOLICY_64')}
                  </span>
                </p>
                <p>
                  ›{' '}
                  <span
                    style={{ marginLeft: '15px' }}
                    className={styles.textType}
                    id="PRIVACYPOLICY_65"
                  >
                    {t('privacyPolicy.PRIVACYPOLICY_65')}
                  </span>
                </p>
                <p>
                  ›{' '}
                  <span
                    style={{ marginLeft: '15px' }}
                    className={styles.textType}
                    id="PRIVACYPOLICY_66"
                  >
                    {t('privacyPolicy.PRIVACYPOLICY_66')}
                  </span>
                </p>
                <p>
                  ›{' '}
                  <span
                    style={{ marginLeft: '15px' }}
                    className={styles.textType}
                    id="PRIVACYPOLICY_67"
                  >
                    {t('privacyPolicy.PRIVACYPOLICY_67')}
                  </span>
                </p>
                <div>
                  <div className={styles.h3header}>
                    <span id="PRIVACYPOLICY_68">
                      {t('privacyPolicy.PRIVACYPOLICY_68')}
                    </span>
                  </div>
                  <p className={styles.paraAlign}>
                    <span className={styles.textType} id="PRIVACYPOLICY_69">
                      {t('privacyPolicy.PRIVACYPOLICY_69')}
                    </span>
                  </p>
                  <p className={styles.paraAlign}>
                    <span className={styles.textType} id="PRIVACYPOLICY_70">
                      {t('privacyPolicy.PRIVACYPOLICY_70')}
                    </span>
                  </p>
                  <p className={styles.paraAlign}>
                    <span className={styles.textType} id="PRIVACYPOLICY_71">
                      {t('privacyPolicy.PRIVACYPOLICY_71')}
                    </span>
                  </p>
                  <p className={styles.paraAlign}>
                    <span className={styles.textType} id="PRIVACYPOLICY_72">
                      {t('privacyPolicy.PRIVACYPOLICY_72')}
                    </span>
                  </p>
                  <div>
                    <div className={styles.h3header}>
                      <span id="PRIVACYPOLICY_73">
                        {t('privacyPolicy.PRIVACYPOLICY_73')}
                      </span>
                    </div>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_74">
                        {t('privacyPolicy.PRIVACYPOLICY_74')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <strong>
                        <span className={styles.textType} id="PRIVACYPOLICY_75">
                          {t('privacyPolicy.PRIVACYPOLICY_75')}
                        </span>
                      </strong>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_76">
                        {t('privacyPolicy.PRIVACYPOLICY_76')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <strong>
                        <span className={styles.textType} id="PRIVACYPOLICY_77">
                          {t('privacyPolicy.PRIVACYPOLICY_77')}
                        </span>
                      </strong>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_78">
                        {t('privacyPolicy.PRIVACYPOLICY_78')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <strong>
                        <span className={styles.textType} id="PRIVACYPOLICY_79">
                          {t('privacyPolicy.PRIVACYPOLICY_79')}
                        </span>
                      </strong>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_80">
                        {t('privacyPolicy.PRIVACYPOLICY_80')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <strong>
                        <span className={styles.textType} id="PRIVACYPOLICY_81">
                          {t('privacyPolicy.PRIVACYPOLICY_81')}
                        </span>
                      </strong>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_82">
                        {t('privacyPolicy.PRIVACYPOLICY_82')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <strong>
                        <span className={styles.textType} id="PRIVACYPOLICY_83">
                          {t('privacyPolicy.PRIVACYPOLICY_83')}
                        </span>
                      </strong>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_84">
                        {t('privacyPolicy.PRIVACYPOLICY_84')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <strong>
                        <span className={styles.textType} id="PRIVACYPOLICY_85">
                          {t('privacyPolicy.PRIVACYPOLICY_85')}
                        </span>
                      </strong>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_86">
                        {t('privacyPolicy.PRIVACYPOLICY_86')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_87">
                        {t('privacyPolicy.PRIVACYPOLICY_87')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <strong>
                        <span className={styles.textType} id="PRIVACYPOLICY_88">
                          {t('privacyPolicy.PRIVACYPOLICY_88')}
                        </span>
                      </strong>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_89">
                        {t('privacyPolicy.PRIVACYPOLICY_89')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_90">
                        {t('privacyPolicy.PRIVACYPOLICY_90')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <strong>
                        <span className={styles.textType} id="PRIVACYPOLICY_91">
                          {t('privacyPolicy.PRIVACYPOLICY_91')}
                        </span>
                      </strong>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_92">
                        {t('privacyPolicy.PRIVACYPOLICY_92')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_93">
                        {t('privacyPolicy.PRIVACYPOLICY_93')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_94">
                        {t('privacyPolicy.PRIVACYPOLICY_94')}
                      </span>
                    </p>
                    <p className={styles.paraAlign}>
                      <span className={styles.textType} id="PRIVACYPOLICY_95">
                        {t('privacyPolicy.PRIVACYPOLICY_95')}
                      </span>
                    </p>
                    <div>
                      <div className={styles.h3header}>
                        <span id="PRIVACYPOLICY_96">
                          {t('privacyPolicy.PRIVACYPOLICY_96')}
                        </span>
                      </div>
                      <p className={styles.paraAlign}>
                        <span className={styles.textType} id="PRIVACYPOLICY_97">
                          {t('privacyPolicy.PRIVACYPOLICY_97')}
                        </span>
                      </p>
                      <p className={styles.paraAlign}>
                        <span className={styles.textType} id="PRIVACYPOLICY_98">
                          {t('privacyPolicy.PRIVACYPOLICY_98')}
                        </span>
                        <a
                          className={styles.anchorTagFont}
                          href="http://www.ico.org.uk/concerns"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span id="PRIVACYPOLICY_98_1">
                            {t('privacyPolicy.PRIVACYPOLICY_98_1')}
                          </span>
                        </a>
                        <span
                          className={styles.textType}
                          id="PRIVACYPOLICY_98_2"
                        >
                          {t('privacyPolicy.PRIVACYPOLICY_98_2')}
                        </span>
                      </p>
                      <div>
                        <div className={styles.h3header}>
                          <span id="PRIVACYPOLICY_99">
                            {t('privacyPolicy.PRIVACYPOLICY_99')}
                          </span>
                        </div>
                        <p className={styles.paraAlign}>
                          <span
                            className={styles.textType}
                            id="PRIVACYPOLICY_100"
                          >
                            {t('privacyPolicy.PRIVACYPOLICY_100')}
                          </span>
                          <a
                            className={styles.anchorTagFont}
                            href="data.protection@mail.weir"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span id="PRIVACYPOLICY_100_1">
                              {t('privacyPolicy.PRIVACYPOLICY_100_1')}
                            </span>
                          </a>
                        </p>
                        <p className={styles.paraAlign}>
                          <span
                            className={styles.textType}
                            id="PRIVACYPOLICY_101"
                          >
                            {t('privacyPolicy.PRIVACYPOLICY_101')}
                          </span>
                        </p>
                        <p className={styles.paraAlign}>&nbsp;</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
