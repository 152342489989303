import axios from 'axios';
import { loaderActions, notificationActions } from '../../shared/_actions';
import { history } from './history';
import { networkStatus } from '../../shared/Helpers/networkStatus';
import { localStorageOperation } from '../../shared/Helpers/localStorageData';
import { localStorageKeys } from '../../shared/_constants/localStorageKeys';
import { applicationRouteConstants } from '../../shared/_constants';
import { notificationConstants } from '../../shared/_constants/notification.constants';

function getHeader(tokenVal) {
  const token = localStorageOperation.getDataFromLocalStorage(
    localStorageKeys.authToken
  );
  let headerData;
  if (tokenVal) {
    headerData = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  } else {
    headerData = {
      'Content-Type': 'application/json',
    };
  }
  return headerData;
}

function getData(url, tokenVal, successCallback) {
  const headerData = getHeader(tokenVal);

  return (dispatch) => {
    dispatch(loaderActions.show());
    return axios
      .get(url, {
        headers: headerData,
      })
      .then((response) => {
        this.apiSuccessHandle(response, successCallback, dispatch);
      })
      .catch((err) => {
        this.apiErrorHandle(err, dispatch);
      });
  };
}

function postData(url, tokenVal, reqBody, successCallback) {
  const headerData = getHeader(tokenVal);

  return (dispatch) => {
    dispatch(loaderActions.show());
    return axios
      .post(url, JSON.stringify(reqBody), {
        headers: headerData,
      })
      .then((response) => {
        this.apiSuccessHandle(response, successCallback, dispatch);
      })
      .catch((err) => {
        this.apiErrorHandle(err, dispatch);
      });
  };
}

function putData(url, tokenVal, reqBody, successCallback) {
  const headerData = getHeader(tokenVal);

  return (dispatch) => {
    dispatch(loaderActions.show());
    return axios
      .put(url, JSON.stringify(reqBody), {
        headers: headerData,
      })
      .then((response) => {
        this.apiSuccessHandle(response, successCallback, dispatch);
      })
      .catch((err) => {
        this.apiErrorHandle(err, dispatch);
      });
  };
}

function apiSuccessHandle(response, successCallback, dispatch) {
  dispatch(loaderActions.hide());
  if (response.status === 200) {
    if (successCallback !== undefined) {
      successCallback(response, dispatch);
    }
  } else {
    dispatch(
      notificationActions.showNotification({
        notificationType: notificationConstants.ERROR,
        notificationTitle: networkStatus[response.status],
        notificationMessage: '',
        onClose: () => {},
      })
    );
  }
}

export function apiErrorHandle(err, dispatch) {
  dispatch(loaderActions.hide());
  if (err.response && err.response.status === 400) {
    dispatch(
      notificationActions.showNotification({
        notificationType: notificationConstants.ERROR,
        notificationTitle: err.response.data.message,
        notificationMessage: '',
        onClose: () => {},
      })
    );
  } else if (err.response && err.response.status === 404) {
    dispatch(
      notificationActions.showNotification({
        notificationType: notificationConstants.ERROR,
        notificationTitle: networkStatus[err.response.status],
        notificationMessage: '',
        onClose: () => {},
      })
    );
  } else if (err.response && err.response.status === 401) {
    dispatch(
      notificationActions.showNotification({
        notificationType: notificationConstants.ERROR,
        notificationTitle: networkStatus[err.response.status],
        notificationMessage: '',
        onClose: () => {},
      })
    );
    localStorageOperation.removeDataFromLocalStorage(
      localStorageKeys.authToken
    );
    localStorageOperation.removeDataFromLocalStorage(
      localStorageKeys.userDetails
    );
    localStorageOperation.removeDataFromLocalStorage(localStorageKeys.appName);
    history.push(`${applicationRouteConstants.LOGIN}`);
  }
}

export const serviceFactory = {
  getData,
  postData,
  putData,
  apiSuccessHandle,
  apiErrorHandle,
};
