/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from '@weirgroup/weir-dls';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styles from './main.module.css';
import TermsConditions from '../../components/TermsConditions';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import CookiesPolicy from '../../components/CookiesPolicy';
import { siteInformationPath } from './constants';
import { history } from '../../services/_helpers';

const SiteInformation = ({ t }) => {
  const isLoggedIn = localStorage.getItem('msal.idtoken');
  let lastUrlItem = history.location.pathname.substring(
    history.location.pathname.lastIndexOf('/') + 1
  );
  lastUrlItem = lastUrlItem ? lastUrlItem.toString().toLowerCase() : '';
  const activeTabIndex = () => {
    let activeIndex = 0;
    if (lastUrlItem === 'cookies-policy') {
      activeIndex = 2;
    } else if (lastUrlItem === 'privacy-statement') {
      activeIndex = 1;
    }
    return activeIndex;
  };

  return (
    <div className={isLoggedIn ? styles.loggedInSiteInfo : styles.siteInfo}>
      <div className={styles.contentAlign}>
        <div data-testid="siteInfo" className={styles.siteInfoTitle}>
          {t('siteInformationTitle')}
        </div>
        <Tabs
          items={[
            {
              title: t('termsAndConditions.TC_Line1'),
              component: <TermsConditions />,
              onClick() {
                history.push(siteInformationPath.TERMSCONDITION);
              },
            },
            {
              title: t('privacyPolicy.PRIVACYPOLICY_1'),
              component: <PrivacyPolicy />,
              onClick() {
                history.push(siteInformationPath.PRIVACYSTMT);
              },
            },
            {
              title: t('cookiesPolicys.CookiesPolicy_1'),
              component: <CookiesPolicy />,
              onClick() {
                history.push(siteInformationPath.COOKIESPOLICY);
              },
            },
          ]}
          activeTabIndex={activeTabIndex()}
        />
      </div>
    </div>
  );
};

export default connect(null, null)(withTranslation()(SiteInformation));

SiteInformation.propTypes = {
  t: PropTypes.func.isRequired,
};
