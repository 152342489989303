const redirectUri = `${window.location.origin}/blank.html`;

export const msalConstants = {
  portalApplicationConfig: {
    clientID: process.env.REACT_APP_CDE_CLIENT_ID, // New CDE Portal (Prod)
    scopes: ['openid', 'user.read'],
    authority:
      'https://login.microsoftonline.com/72ca17d4-d1db-496b-8e11-2617ae372f4f',
  },
  apiApplicationConfig: {
    clientID: process.env.REACT_APP_CDE_CLIENT_ID, // Apis-Production-AppRegistration
    scopes: [
      'api://885ee620-55e8-438c-a1fe-ed82671a2450/allresources.access.asuser',
      'api://b7785d7d-83b5-45e6-a16a-f6658510e3f0/access.as.user',
      'openid',
      'user.read',
    ],
    authority:
      'https://login.microsoftonline.com/72ca17d4-d1db-496b-8e11-2617ae372f4f',
  },
  redirectUri,
};

export const apiRedirectUri = `${window.location.origin}/blank.html`;
