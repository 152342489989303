import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { applicationRouteConstants } from '../../shared/_constants';

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        props.location.state === undefined ? (
          <Redirect to={applicationRouteConstants.DEFAULT} />
        ) : (
          children
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.func.isRequired,
  state: PropTypes.func.isRequired,
};

export default PrivateRoute;
