import React from 'react';
import { SpinnerCircular } from '@weirgroup/weir-dls';
import styles from './Loader.module.css';
/**
 *
 * Common Loader component
 */
export default function Loader() {
  return (
    <div className={styles.loading} data-testid="loader">
      <SpinnerCircular />
    </div>
  );
}
