import {
  MY_ACTIVITY_DATA,
  GET_ACTIVITY_DATA_FAILURE,
} from '../Actions/ActionTypes';

const initialState = {
  myActivities: [],
  activityDataFailure: null,
};

export default function MyActivityReducer(state = initialState, action) {
  switch (action.type) {
    case MY_ACTIVITY_DATA:
      return {
        ...state,
        myActivities: action.payload,
        activityDataFailure: null,
      };
    case GET_ACTIVITY_DATA_FAILURE:
      return {
        ...state,
        activityDataFailure: action.payload,
        myActivities: [],
      };
    default:
      return state;
  }
}
