function storeDatatoLocalStorage(storageName, data) {
  localStorage.setItem(storageName, JSON.stringify(data));
}

function getDataFromLocalStorage(storageName) {
  if (localStorage.getItem(storageName) !== null) {
    return localStorage.getItem(storageName);
  }

  return null;
}

function removeDataFromLocalStorage(storageName) {
  localStorage.removeItem(storageName);
}

function removeAllStorages() {
  localStorage.clear();
}

export const localStorageOperation = {
  storeDatatoLocalStorage,
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
  removeAllStorages,
};
