import { userConstants } from './constants';

const initialState = {
  appManifest: {},
  signUpStatus: false,
  signUpSuccess: false,
  duplicateUser: false,
  deniedUser: false,
};

export default function appsignUp(state = initialState, action = {}) {
  switch (action.type) {
    case userConstants.SIGNUP_SCREEN:
      return {
        ...state,
        signUpStatus: action.payload,
      };
    case userConstants.SIGNUP_SUCCESS:
      return {
        ...state,
        signUpSuccess: action.payload,
      };
    case userConstants.DUPLICATE_USER:
      return {
        ...state,
        duplicateUser: action.payload,
      };
    case userConstants.DENIED_USER:
      return {
        ...state,
        deniedUser: action.payload,
      };
    case userConstants.APPLICATION_MANIFEST:
      return {
        ...state,
        appManifest: action.payload,
      };
    default:
      return state;
  }
}
