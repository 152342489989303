import {
  MY_ACCESS_DATA,
  GET_ACCESS_DATA_FAILURE,
} from '../Actions/ActionTypes';

const initialState = {
  myAccessData: [],
  accessDataFailure: null,
};

export default function MyAccessReducer(state = initialState, action) {
  switch (action.type) {
    case MY_ACCESS_DATA:
      return {
        ...state,
        myAccessData: action.myAccessData,
        accessDataFailure: null,
      };
    case GET_ACCESS_DATA_FAILURE:
      return {
        ...state,
        accessDataFailure: action.error,
        myAccessData: [],
      };
    default:
      return state;
  }
}
