export const applicationRouteConstants = {
  // DEFAULT: `${applicationName}`,
  LOGIN: `/login`,
  HUB: `/hub`,
  SiteInformation: `/site-information`,
  SIGNUP: `/register`,
  MY_ACCOUNT: `/my-account/access`,
  MY_ACCESS: '/my-access',
  MY_MYAPPS: '/my-access?myapps',
  MY_MOREAPPS: '/my-access?moreapps',
  MY_ACTIVITY: '/my-activity',
  SELECTION_PAGE: '/selection-page',
};
