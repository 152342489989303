import { notificationConstants } from '../_constants';

const initialState = {
  showNotification: false,
  notificationType: '',
  notificationTitle: '',
  notificationMessage: '',
  callToActionList: [],
  onClose: () => {},
};

export function notification(state = initialState, action) {
  switch (action.type) {
    case notificationConstants.HIDE_NOTIFICATION: {
      return {
        ...state,
        showNotification: false,
        notificationType: '',
        notificationTitle: '',
        notificationMessage: '',
        callToActionList: [],
        onClose: () => {},
      };
    }

    case notificationConstants.SHOW_NOTIFICATION: {
      return {
        ...state,
        showNotification: true,
        callToActionList: action.payload.callToActionList
          ? action.payload.callToActionList
          : [],
        onClose: action.payload.onClose ? action.payload.onClose : () => {},
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
