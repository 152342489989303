/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-useless-return */
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';

const crypto = require('crypto');

Object.defineProperty(window, 'crypto', crypto);

const redirectUri = `${window.location.origin}/blank.html`;
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CDE_CLIENT_ID, // New CDE Portal (Prod)
    authority:
      'https://login.microsoftonline.com/72ca17d4-d1db-496b-8e11-2617ae372f4f',
    redirectUri,
    postLogoutRedirectUri: `${window.location.origin}`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Trace,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Create an instance of PublicClientApplication
export const msalInstance = new PublicClientApplication(msalConfig);

// Initialize MSAL application
async function initializeMsal() {
  try {
    await msalInstance.initialize();
    console.log('msal initialized successfully');
    // MSAL is initialized, you can now use other MSAL APIs
  } catch (error) {
    console.error('Error initializing MSAL:', error);
  }
}

// Call the initializeMsal function to start the initialization process
initializeMsal();

export const checkAndSetActiveAccount = () => {
  if (
    msalInstance.getAllAccounts().length > 0 &&
    !msalInstance.getActiveAccount()
  ) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
};

export const loginSSO = async (username) => {
  const loginRequest = {
    scopes: ['openid', 'user.read'],
    loginHint: username,
  };
  const account = await msalInstance
    .ssoSilent(loginRequest)
    .then(() => {
      checkAndSetActiveAccount();
      const user = msalInstance.getAccountByUsername(username);
      if (user) {
        return user;
      }
    })
    .catch((err) => {
      console.error(`78: ${err}`);
    });
  return account;
};

const isTokenExpired = (token) => {
  if (!token) return true;
  const { exp } = jwtDecode(token);
  if (!exp) return true;
  const currentTime = Math.floor(Date.now() / 1000);
  return exp < currentTime;
};

export const getApiToken = async (scope, loginHint) => {
  const user = msalInstance.getAccountByUsername(loginHint);
  const tokenRequest = {
    scopes: scope,
    account: user,
  };

  const scopeVal = scope[0];
  if (scopeVal.indexOf('allresources.access.asuser') > -1) {
    // Check for existing token and its validity
    if (
      window.localStorage.getItem('cdeSharedApisScopeToken') !== null &&
      window.localStorage.getItem('cdeSharedApisScopeToken') !== undefined &&
      window.localStorage.getItem('cdeSharedApisScopeToken') !== 'null'
    ) {
      const token = window.localStorage.getItem('cdeSharedApisScopeToken');
      if (!isTokenExpired(token)) {
        return token;
      }
      try {
        const newToken = await msalInstance.acquireTokenPopup(tokenRequest);
        window.localStorage.setItem(
          'cdeSharedApisScopeToken',
          newToken.accessToken
        );
        return newToken.accessToken;
      } catch (error) {
        console.error(`110: ${error}`);
      }
    } else {
      // Check for existing token and its validity
      const accounts = msalInstance.getAllAccounts();
      if (accounts?.length > 0) {
        checkAndSetActiveAccount();
        await loginSSO(loginHint);

        try {
          const cachedToken =
            await msalInstance.acquireTokenSilent(tokenRequest);
          if (!isTokenExpired(cachedToken.accessToken)) {
            window.localStorage.setItem(
              'cdeSharedApisScopeToken',
              cachedToken.accessToken
            );
            return cachedToken.accessToken;
          }
        } catch (err) {
          console.error(`99: ${err}`);
          // could also check if err instance of InteractionRequiredAuthError if you can import the class.
          if (err.name === 'InteractionRequiredAuthError') {
            return msalInstance
              .acquireTokenPopup(tokenRequest)
              .then((response) => {
                // get access token from response
                window.localStorage.setItem(
                  'cdeSharedApisScopeToken',
                  response.accessToken
                );
                return response.accessToken;
              })
              .catch((error) => {
                // handle error
                console.error(`110: ${error}`);
              });
          }
        }

        try {
          const newToken = await msalInstance.acquireTokenPopup(tokenRequest);
          window.localStorage.setItem(
            'cdeSharedApisScopeToken',
            newToken.accessToken
          );
          return newToken.accessToken;
        } catch (error) {
          console.error(`110: ${error}`);
        }
      }
      return null;
    }
  } else {
    // Check for existing token and its validity
    if (
      window.localStorage.getItem('cdeScopeToken') !== null &&
      window.localStorage.getItem('cdeScopeToken') !== undefined &&
      window.localStorage.getItem('cdeScopeToken') !== 'null'
    ) {
      const token = window.localStorage.getItem('cdeScopeToken');
      if (!isTokenExpired(token)) {
        return token;
      }
      try {
        const newToken = await msalInstance.acquireTokenPopup(tokenRequest);
        window.localStorage.setItem('cdeScopeToken', newToken.accessToken);
        return newToken.accessToken;
      } catch (error) {
        console.error(`110: ${error}`);
      }
    } else {
      // Check for existing token and its validity
      const accounts = msalInstance.getAllAccounts();
      if (accounts?.length > 0) {
        checkAndSetActiveAccount();
        await loginSSO(loginHint);

        try {
          const cachedToken =
            await msalInstance.acquireTokenSilent(tokenRequest);
          if (!isTokenExpired(cachedToken.accessToken)) {
            window.localStorage.setItem(
              'cdeScopeToken',
              cachedToken.accessToken
            );
            return cachedToken.accessToken;
          }
        } catch (err) {
          console.error(`99: ${err}`);
          // could also check if err instance of InteractionRequiredAuthError if you can import the class.
          if (err.name === 'InteractionRequiredAuthError') {
            return msalInstance
              .acquireTokenPopup(tokenRequest)
              .then((response) => {
                // get access token from response
                window.localStorage.setItem(
                  'cdeScopeToken',
                  response.accessToken
                );
                return response.accessToken;
              })
              .catch((error) => {
                // handle error
                console.error(`110: ${error}`);
              });
          }
        }

        try {
          const newToken = await msalInstance.acquireTokenPopup(tokenRequest);
          window.localStorage.setItem('cdeScopeToken', newToken.accessToken);
          return newToken.accessToken;
        } catch (error) {
          console.error(`110: ${error}`);
        }
      }
      return null;
    }
  }
};
