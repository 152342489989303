export const userConstants = {
  APPLICATION_MANIFEST: 'APPLICATION_MANIFEST',
  LOGOUT: 'USERS_LOGOUT',
  LOGIN_SCREEN: 'LOGIN_SCREEN',
  SIGNUP_SCREEN: 'SIGNUP SCREEN',
  DUPLICATE_USER: 'DUPLICATE_USER',
  DENIED_USER: 'DENIED_USER',
  SIGNUP_SUCCESS: 'SIGNUP SUCESS',
  LOAD_SCREEN: 'LOAD_SCREEN',
  EMAIL_RULES: /^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i,
  DEFAULT_LOGIN: '/login',
  IMAGE_BASEURL: 'https://lively-beach-0bd005610.azurestaticapps.net/assets/',
};
