import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../resources/stylesheets/shared.module.css';

/* This generates the Terms & Conditions templates */
export default function TermsConditions() {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.h1headerAlign}>
        <span className={styles.h1header} data-testid="TC_Line1">
          {t('termsAndConditions.TC_Line1')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line2">
          {' '}
          {t('termsAndConditions.TC_Line2')}{' '}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line3">
          {t('termsAndConditions.TC_Line3')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line4">
          {t('termsAndConditions.TC_Line4')}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line5">
          {t('termsAndConditions.TC_Line5')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line6">
          {t('termsAndConditions.TC_Line6')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line7">
          {t('termsAndConditions.TC_Line7')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line8">
          {t('termsAndConditions.TC_Line8')}
        </span>
      </p>
      {/*  synertrex  T&Cs start */}
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line8_1">
          {t('termsAndConditions.TC_Line8_1')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line8_2">
          {t('termsAndConditions.TC_Line8_2')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line8_3">
          {t('termsAndConditions.TC_Line8_3')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line8_4">
          {t('termsAndConditions.TC_Line8_4')}
        </span>
      </p>
      {/*  synertrex  T&Cs end */}
      <div>
        <span className={styles.h3header} id="TC_Line9">
          {t('termsAndConditions.TC_Line9')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line10">
          {t('termsAndConditions.TC_Line10')}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line11">
          {t('termsAndConditions.TC_Line11')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line12">
          {t('termsAndConditions.TC_Line12')}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line13">
          {t('termsAndConditions.TC_Line13')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line14">
          {t('termsAndConditions.TC_Line14')}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line15">
          {t('termsAndConditions.TC_Line15')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line16">
          {t('termsAndConditions.TC_Line16')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line17">
          {t('termsAndConditions.TC_Line17')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line18">
          {t('termsAndConditions.TC_Line18')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line19">
          {t('termsAndConditions.TC_Line19')}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line20">
          {t('termsAndConditions.TC_Line20')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line21">
          {t('termsAndConditions.TC_Line21')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line22">
          {t('termsAndConditions.TC_Line22')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line23">
          {t('termsAndConditions.TC_Line23')}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line24">
          {t('termsAndConditions.TC_Line24')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line25">
          {t('termsAndConditions.TC_Line25')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line26">
          {t('termsAndConditions.TC_Line26')}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line27">
          {t('termsAndConditions.TC_Line27')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line28">
          {t('termsAndConditions.TC_Line29')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line29">
          {t('termsAndConditions.TC_Line29')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line30">
          {t('termsAndConditions.TC_Line30')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line31">
          {t('termsAndConditions.TC_Line31')}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line32">
          {t('termsAndConditions.TC_Line32')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line33">
          {t('termsAndConditions.TC_Line33')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line34">
          {t('termsAndConditions.TC_Line34')}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line35">
          {t('termsAndConditions.TC_Line35')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line36">
          {t('termsAndConditions.TC_Line36')}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line37">
          {t('termsAndConditions.TC_Line37')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line38">
          {t('termsAndConditions.TC_Line38')}
        </span>
      </p>
      <div>
        <span className={styles.h3header} id="TC_Line39">
          {t('termsAndConditions.TC_Line39')}
        </span>
      </div>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line40">
          {t('termsAndConditions.TC_Line40')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line41">
          {t('termsAndConditions.TC_Line41')}
        </span>
      </p>
      <p className={styles.paraAlign}>
        <span className={styles.textType} id="TC_Line42">
          {t('termsAndConditions.TC_Line42')}
        </span>
      </p>
    </div>
  );
}
