import { SET_AUTH } from './ActionTypes';

const initialState = {
  isAuthorised: null,
  isAuthenticated: null,
  user: '',
};
/**
 * Reducer for Auth
 * @param  state
 * @param  action
 */
export default function AuthReducer(state = initialState, action) {
  if (action.type === SET_AUTH) {
    return {
      ...state,
      isAuthorised: action.payload.Authorised,
      isAuthenticated: action.payload.Authenticated,
      user: action.payload.user,
    };
  } else {
    return state;
  }
}
